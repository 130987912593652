import * as names from '@/store/names';
import { APP_NAME } from "@/utils/globals";
import * as get from '@/store/store';

const state = {
    allows_crud: JSON.parse(localStorage.getItem('allows_crud')) != undefined ? JSON.parse(localStorage.getItem('allows_crud')) : false,
    visibility_crud: false,
    print_orientation: false,
    title_of_the_print: APP_NAME,
    print_zoom_scale: 1,
    numbers_to_words: [],
};

const getters = {
    [names.NUMBERS_TO_WORDS]: state => {
        return state.numbers_to_words;
    },
    [names.ALLOWS_CRUD]: state => {
        return state.allows_crud;
    },
    [names.VISIBILITY_CRUD]: state => {
        return state.visibility_crud;
    },
    [names.PRINT_ZOOM_SCALE]: state => {
        return state.print_zoom_scale;
    },
    [names.PRINT_ORIENTATION]: state => {
        return state.print_orientation;
    },
    [names.TITLE_OF_THE_PRINT]: state => {
        return state.title_of_the_print;
    },
    getLocalVariable: (state, getters) => (component, variable) => {
        const userId = get.store.state.user.id
        return getters.getLocalVariableByUser(component, variable, userId)
        // Lista de variables usadas: 
        // hide_number, hide_dimension, hide_weighing, hide_evaluation_criteria.
    },
    getLocalVariableByUser: () => (component, variable, userId) => {
        return JSON.parse(localStorage.getItem(`${component}_${variable}_${userId}`)) || false;
    },
};

const mutations = {
    [names.MUTATE_ALLOWS_CRUD]: (state, allows_crud) => {
        state.allows_crud = allows_crud;
        localStorage.setItem("allows_crud", JSON.stringify(allows_crud));
    },
    [names.MUTATE_VISIBILITY_CRUD]: (state, visibility_crud) => {
        state.visibility_crud = visibility_crud;
    },
    [names.MUTATE_PRINT_ZOOM_SCALE]: (state, print_zoom_scale) => {
        state.print_zoom_scale = print_zoom_scale;
    },
    [names.RESET_PRINT_ZOOM_SCALE]: (state) => {
        state.print_zoom_scale = 0;
    },
    [names.MUTATE_PRINT_ORIENTATION]: (state, print_orientation) => {
        state.print_orientation = print_orientation;
    },
    [names.RESET_PRINT_ORIENTATION]: (state) => {
        state.print_orientation = null;
    },
    [names.MUTATE_TITLE_OF_THE_PRINT]: (state, title_of_the_print) => {
        state.title_of_the_print = title_of_the_print;
    },
    [names.RESET_TITLE_OF_THE_PRINT]: (state) => {
        state.title_of_the_print = null;
    },
    ['MUTATE_NUMBERS_TO_WORDS']: (state, mutate) => {
        state.numbers_to_words = mutate;
    },
    setLocalVariable: (state, { component, variable, value }) => {
        const userId = get.store.state.user.id
        localStorage.setItem(`${component}_${variable}_${userId}`, JSON.stringify(value));
        // Lista de variables usadas: 
        // hide_number, hide_dimension, hide_weighing, hide_evaluation_criteria.
    },
};
const actions = {
    ["CHECK_NUMBERS_TO_WORDS"]: ({ commit, state }, check_number) => {
        return new Promise((resolve) => {
            if (parseInt(check_number) <= state.numbers_to_words.length) {
                resolve(state.numbers_to_words);
                return;
            } else {
                const unidades = ['PRIMERO', 'SEGUNDO', 'TERCERO', 'CUARTO', 'QUINTO', 'SEXTO', 'SÉPTIMO', 'OCTAVO', 'NOVENO'];
                const decenas = ['DÉCIMO', 'VIGÉSIMO', 'TRIGÉSIMO', 'CUADRAGÉSIMO', 'QUINCUAGÉSIMO', 'SEXAGÉSIMO', 'SEPTUAGÉSIMO', 'OCTOGÉSIMO', 'NONAGÉSIMO'];
                let numbers_to_words = [...state.numbers_to_words];
                for (let i = state.numbers_to_words.length + 1; i <= parseInt(check_number); i++) {
                    let nombre = '';
                    if (i <= 9) {
                        nombre = unidades[i - 1];
                    } else if (i % 10 === 0) {
                        let decena = Math.floor(i / 10);
                        nombre = decenas[decena - 1];
                    } else {
                        let decena = Math.floor(i / 10);
                        let unidad = i % 10;
                        nombre = decenas[decena - 1] + ` ${unidades[unidad - 1]}`;
                    }
                    numbers_to_words.push(nombre);
                }
                // Usar una mutación para actualizar el estado.
                commit('MUTATE_NUMBERS_TO_WORDS', numbers_to_words);
                resolve(numbers_to_words);
            }
        });
    },
};

export default {
    state,
    getters,
    mutations,
    actions,
};